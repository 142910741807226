import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { ToastContainer } from "react-toastify"
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "../../../styles/breakpoints"

// SEO
import SeoPicker from "../../templates/Seo/SeoPicker"

// Toastify Components
import "react-toastify/dist/ReactToastify.css"
import CategoryBoxes from "./CategoryBoxes"
import DesktopSideFilters from "./DesktopSideFilters"
import CategoryRow from "./CategoryRow"


const PageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: var(--lightgreen);
  padding-top: 10vh;

  position: relative;
`

const Top = styled.div`
  width: 80%;
  //height: 15vh;
  margin-top: 30px;

  position: absolute;

  right: 0;
  padding: 0 2.5vh;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .searchbox {
    width: 100%;
    margin-bottom: 2vh;
  }

  @media screen and (max-width: ${TABLET_BREAKPOINT}) {
    width: 100%;

    position: static;

    flex-direction: column;
    padding-top: 10px;
    //padding-bottom: 20px;

    .hidemobile {
      display: none;
    }
    .searchbox {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`

const Center = styled.div`
  margin-top: 22vh;
  width: calc(80% - 30px);
  margin-bottom: 15vh;
  margin-left: 30px;
  @media screen and (max-width: ${TABLET_BREAKPOINT}) {
    width: 100%;
    margin-left: 20px;
    margin-right: 0px;
    margin-top: 80px;
    margin-bottom: 40px;
  }
`

const LeftSide = styled.div`
  height: 100%;
  width: 20%;
  margin-top: 30px;

  @media screen and (max-width: ${TABLET_BREAKPOINT}) {
    margin-top: 0;
    width: 100%;
    display: none;
  }
`

const ExtrasWrapper = styled.div`

`

const ProductRow = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 275px);
  overflow-y: hidden;
  overflow-x: scroll;
  grid-gap: 10px;
`

const categories = [
  {catName: "FLOWER", title: "Popular Flowers"},
  {catName: "EDIBLES", title: "Popular Edibles"},
  {catName: "VAPORIZERS", title: "Popular Vapes"},
  {catName: "PRE_ROLLS", title: "Popular Pre-Rolls"},
  {catName: "CONCENTRATES", title: "Popular Concentrates"},
  {catName: "TOPICALS", title: "Popular Topicals"},
  {catName: "ACCESSORIES", title: "Popular Accessories"},
  {catName: "EDIBLES", subCatName: "DRINKS", title: "Popular Drinks"},
  {catName: "CONCENTRATES", subCatName: "OIL", title: "Popular Oils"},
]

export default function LandingMenuHub({ prerenderdata }) {

  const {filterCategories} = useStaticQuery(graphql`
    {
      filterCategories: strapiFilterSettings {
        Categories {
          Slug
          Name
          MetadataTitle
          MetadataDescription
          SubCategories {
            Name
            Slug
            MetadataTitle
            MetadataDescription
          }
        }
      }
    }
  `)

  return (
    <>
      <SeoPicker
        prerenderdata={prerenderdata}
        cmsCategoryData={filterCategories}
      />
      <PageWrapper>
        <Top>
          <CategoryBoxes categories={filterCategories.Categories} />
        </Top>

        <LeftSide>
          <DesktopSideFilters cmsCategoryData={filterCategories} />
        </LeftSide>

        <Center>

          {categories.map(item => {


            return (
              <CategoryRow
                key={item.catName}
                catName={item.catName}
                subCat={item?.subCatName}
                title={item.title}/>
            )
          })}
        </Center>

        <ExtrasWrapper>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            pauseOnHover={false}
          />
        </ExtrasWrapper>
      </PageWrapper>
    </>
  )
}
