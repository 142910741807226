import React from 'react'
import styled from "styled-components";
import { navigate } from "gatsby"

// Assets
import ArrowRightGreen from '../../../images/icons/arrow-right-green.svg';
import ArrowRightShadow from '../../../images/icons/arrow-right-shadow.svg';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';



const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .swiper-slide{
        @media (min-width: 800px){
            width: 130px !important;
        }
        @media (min-width: 1300px){
            width: 170px !important;
        }
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: white;

    padding: 15px 15px;
    margin-right: 10px;
    

    .name{
        margin-bottom: 12px;
        font-family: IBM Plex Mono Bold;
        font-size: max(2vh, 16px);

        text-overflow: ellipsis;
        overflow: hidden;
        @media (max-width: 1300px){
            font-size: 13px;
        }
        @media (max-width: 800px){
            font-size: 14px;
        }
    }
`

const ShopButtonWrapper = styled.div`
    display: flex;
    align-items: center;

    width: fit-content;

    border-bottom: 2px solid var(--darkgreen);

    .text{
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        font-family: IBM Plex Mono SemiBold;
        text-transform: uppercase;
        margin-right: 15px;
        transition: margin-right 200ms;
        @media (max-width: 1300px){
            font-size: 13px;
            margin-right: 4px;
        }
        @media (max-width: 800px){
            font-size: 14px;
        }
    }

    &:hover{
        cursor: pointer;

        @media (min-width: 1300px){
            .text{
                margin-right: 20px;
            }
        }   
    }
    
`

// Swiper Notes:
//  - centeredSlides
//  - centeredSlidesBounds 

export default function CategoryBoxes({ categories })
{
    return(
        <Wrapper>
            <Swiper
                className="swiper-product-row" 
                spaceBetween={3} 
                slidesPerView={2.3}
                
                breakpoints={{
                    "640": {
                        "slidesPerView": 7,
                        "spaceBetween": 8
                    },
                    "1350": {
                        "slidesPerView": 7,
                        "spaceBetween": 30
                    }
                }} 
            >
            {categories?.map((category, index) => {
                return(
                    <SwiperSlide
                            key={index}
                            virtualIndex={index}
                    >
                        <Box key={category.Slug}>
                            <p className="name">{category.Name}</p>

                            <ShopButtonWrapper
                                onClick={() => navigate(`/menu/${category.Slug.toLowerCase()}`)}
                            >
                                <p className="shop text">
                                    Shop Now
                                </p>
                                <ArrowRightGreen/>
                            </ShopButtonWrapper>
                        </Box>
                    </SwiperSlide>
                )
            })}
            </Swiper>
        </Wrapper>
    )
}