import React from 'react';
import styled from 'styled-components';

import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints';

const Wrapper = styled.div`
    border-top: 1px solid rgba(147, 172, 166, 0.5);
    border-bottom: 1px solid rgba(147, 172, 166, 0.5);

    display: flex;
    flex-direction: column;

    width: 100%;

    .title{
        font-family: IBM Plex Mono Bold;
        text-transform: capitalize;
        font-size: 18px;
        font-weight: 500;

        margin: 10px 20px;

        &:hover{
            cursor: pointer;
        }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}){
        display: none;
    }
`;

export default function DesktopSideFilters({ cmsCategoryData }){
    return(
        <Wrapper>
            {cmsCategoryData?.Categories?.map((category) => {
                return(
                    <a 
                        className="title"
                        key={category.Slug}
                        href={`/menu/${category.Slug.toLowerCase()}`}
                    >
                        {category.Name}
                    </a>
                )
            })}
        </Wrapper>
    )
}