import React, { useContext } from 'react'
import {Link} from 'gatsby';
import styled from 'styled-components';
import { MOBILE_BREAKPOINT } from '../../../styles/breakpoints'


import ProductCard from '../product/ProductCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { useGetAllProducts } from "../../../hooks/useGetAllProducts"
import Loader from "../Others/Loader"
import { FiltersContext } from "../../../contexts/filters"
SwiperCore.use([Pagination, Navigation]);

const Container = styled.div`
    .swiper-button-next{
        background: linear-gradient(270deg, #E3EFEA 20%, rgba(227, 239, 234, 0) 100%);
        height: 100%;
        top: 0;
        right: 0;
        width: 225px;
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        color: var(--darkgreen);
    }
    .swiper-button-prev{
        background: linear-gradient(270deg, rgba(227, 239, 234, 0) 20%,   #E3EFEA 100%);
        height: 100%;
        top: 0;
        left: 0;
        width: 225px;
        display: flex;
        justify-content: flex-start;
        padding-left: 10px;
        color: var(--darkgreen);
    }
    .swiper-button-disabled{
        display: none;
    }
    .swiper-product-row{
        height: 100%;
        padding: 40px 10px;
        padding-bottom: 70px;
        padding-left: 5px;
        padding-right: 5px;
        margin-left: -5px;
        margin-bottom: 20px;
    }
    .swiper-pagination{
        margin-bottom: -10px;
    }
    .swiper-pagination-bullet-active{
        color: var(--darkgreen);
    }

    .swiper-pagination-bullet{
        color: var(--darkgreen);
        background-color: var(--darkgreen)
    }
    
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        width: 100%;
        margin-top: 0px;
        .swiper-pagination-bullet{
            display: none;
        }
        .swiper-button-prev{
            display: none;
        }
        .swiper-button-next{
            display: none;
        }
    }
`;

const TitleRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h2{
        font-family: "Druk Wide Bold";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
    }

    .link-title{
        margin-left: 25px;
        text-transform: uppercase;
        text-decoration: underline;
        &:after{
          content: ' →';
          opacity: 0;
          transition: .25s ease-in-out;
        }
        &:hover{
            &:after {
              opacity: 1;
            }
        }
        
    }
`;

  export default function CategoryRow({catName, subCat, title}){

  const {data: productsData, loading} = useGetAllProducts({category: catName, subcategory: subCat})
  const products = productsData?.menu.products

  if(!products || products?.length === 0) {
    return <div></div>
  }
  return(
    <Container>
      <TitleRow><h2>{title}</h2> <div className="link-title"><Link to={`/menu/${catName.toLowerCase()}`}>Shop All {catName}</Link></div></TitleRow>
      <Swiper
        className="swiper-product-row"
        spaceBetween={10}
        slidesPerView={1.5}
        slidesPerGroup={1}
        pagination={{
          "clickable": true
        }}
        navigation={true}
        breakpoints={{
          "640": {
            "slidesPerView": 2.5,
            "spaceBetween": 15
          },
          "900": {
            "slidesPerView": 4,
            "spaceBetween": 15
          }
        }}
      >
        {
          products?.map(item => {
            return (
              <>
                {!loading
                  ?<SwiperSlide
                    key={item.id}
                  >
                    <ProductCard product={item}/>
                  </SwiperSlide>
                  : <Loader/>}
              </>
            )
          })
        }
      </Swiper>
    </Container>
  )
}